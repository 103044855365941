const DashboardRoutes = [
    '/municipalidad/dashboard',
    '/empresa/dashboard',
    '/municipalidad/dashboard/perfil',
    '/empresa/dashboard/perfil',
    '/municipalidad/dashboard/usuarios',
    '/empresa/dashboard/usuarios',
    '/municipalidad/dashboard/crear-empleo',
    '/empresa/dashboard/crear-empleo',
    '/municipalidad/dashboard/editar-empleo',
    '/empresa/dashboard/editar-empleo',
    '/municipalidad/dashboard/postulantes',
    '/empresa/dashboard/postulantes',
    '/municipalidad/dashboard/postulante/perfil/:postulant_id',
    '/empresa/dashboard/postulante/perfil/:postulant_id',
    '/municipalidad/dashboard/ver-posicion',
    '/empresa/dashboard/ver-posicion',
    '/empresa/dashboard/cambiar-password',
    '/municipalidad/dashboard/cambiar-password',
    '/empresa/dashboard/historial-de-publicaciones',
    '/municipalidad/dashboard/historial-de-publicaciones',
    '/empresa/dashboard/republicar-posicion',
    '/municipalidad/dashboard/republicar-posicion',
    '/empresa/dashboard/ver-detalles-de-posicion',
    '/municipalidad/dashboard/ver-detalles-de-posicion',
    '/empresa/dashboard/empleo-registrado',
    '/municipalidad/dashboard/empleo-registrado',
    '/empresa/dashboard/publicacion/:publication_id/lista-de-postulantes',
    '/municipalidad/dashboard/publicacion/:publication_id/lista-de-postulantes',
    '/empresa/dashboard/multiposting',
    '/municipalidad/dashboard/multiposting',
    '/empresa/dashboard/publicacion/:publication_id/asignar-evaluaciones',
    '/municipalidad/dashboard/publicacion/:publication_id/asignar-evaluaciones',
    '/empresa/dashboard/lista-de-postulantes/:postulant_id/perfil',
    '/municipalidad/dashboard/lista-de-postulantes/:postulant_id/perfil',
];
export default DashboardRoutes;