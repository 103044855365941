const TYPE_OF_CONTRACT = [
    {id: 1, name:"Tiempo completo"},
    {id: 2, name:"Medio tiempo"},
    {id: 3, name:"Por horas"},
    {id: 4, name:"Fin de semana"},
]

const TYPE_PAYMENT = [
    {id: 1, name:"SOLES"},
    {id: 2, name:"USD"},
]


export {
    TYPE_OF_CONTRACT,
    TYPE_PAYMENT
}