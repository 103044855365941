export const ApplicantType = {
    SET_PUBLICATIONS_ACCOUNT: 'APPLICANT/SET_PUBLICATIONS_ACCOUNT',
    SET_PUBLICATIONS_ACCOUNT_ERROR: 'APPLICANT/SET_PUBLICATIONS_ACCOUNT_ERROR',
    SET_PUBLICATION_SELECTED: 'APPLICANT/SET_PUBLICATION_SELECTED',
    SET_STATUS: 'APPLICANT/SET_STATUS',
    SET_PUBLICATION_ACCOUNT_SELECTED : 'APPLICANT/SET_PUBLICATION_ACCOUNT_SELECTED',
    SET_PUBLICATIONS_SEARCH : 'APPLICANT/SET_PUBLICATIONS_SEARCH',
    SET_APPLICANT_MESSAGES : 'APPLLICANT/SET_APPLICANT_MESSAGES',
    SET_APPLICANT_MESSAGE_DETAIL : 'APPLICANT/SET_APPLICANT_MESSAGE_DETAIL',
    SET_APPLICANT_QUESTIONS : 'APPLICANT/SET_APPLICANT_QUESTIONS',
    SET_APPLICANT_MEDICAL_TEST : 'APPLICANT/SET_APPLICANT_MEDICAL_TEST',
    SET_APPLICANT_INTERVIEW : 'APPLICANT/SET_APPLICANT_INTERVIEW',
    SET_APPLICANT_JOB_HUNTING_ACTIONS : 'APPLICANT/SET_APPLICANT_JOB_HUNTING_ACTIONS',
    SET_APPLICANT_SELECTED_QUESTION :  'APPLICANT/SET_APPLICANT_SELECTED_QUESTION'
};
