import React from 'react'

export default function PoliciesOfPrivacity(props) {


    return (
        <div className="p-4 text-justify">
            <p><b>SOLUCIONES EN GESTION DE PERSONAL S.A.C.</b>, en adelante, “<b>Operativa</b>” valora a sus Usuarios y está comprometida con la protección de su privacidad y la confidencialidad de la información. Como parte de dicho compromiso, hemos desarrollado la presente política de privacidad, en adelante, la “Política de Privacidad”, que describe la manera en la que <strong>Operativa</strong> tratará sus datos personales en el marco de la relación que mantiene con sus Usuarios (Postulantes Usuarios o Empresas Clientes) para el uso de los Servicios o acceso a la Plataforma.</p>
            <h3>I.	ASPECTOS GENERALES</h3>
            <p>1.1	SOLUCIONES EN GESTIÓN DE PERSONAL S.A.C., es una sociedad debidamente inscrita en el Registro de Personas Jurídicas de la Superintendencia Nacional de los Registros Públicos - Sede Lima, identificada con R.U.C. N° 20549306072, siendo su domicilio fiscal el Jirón Alfonso Ugarte Nº 235 - Oficina 103, distrito de Barranco, provincia y región Lima.</p>
            <p>1.2	Al registrarse en la Plataforma, los Usuarios, acepta haber sido debidamente informado por <b>Operativa</b> de las prácticas, usos y políticas que se detallan a continuación. Esta Política de Privacidad contiene las prácticas de privacidad de la Plataforma operada por <strong>Operativa</strong> en cumplimiento de la Ley N° 29733 - Ley de Protección de Datos Personales, en adelante, “LPDP”, y el Decreto Supremo N° 003-2013-JUS, Reglamento de la Ley de Protección de Datos Personales, en adelante, “RLPDP".</p>
            <p>1.3	Esta Política de Privacidad tiene como finalidad informar cómo <b>Operativa</b> realiza el tratamiento de los datos personales que recopila a través de la Plataforma y que almacena en un <b>Banco de Datos</b> de titularidad de <b>Operativa</b>. <b>Operativa</b> de acuerdo a la Ley N° 27806 - Ley de Transparencia y Acceso a Información Pública, no vulnera en lo absoluto el derecho al trabajo y el principio de igualdad de oportunidades sin discriminación, toda vez que, la información obtenida, ha sido previamente proporcionada de manera voluntaria por los propios Usuarios y bajo los parámetros de la Ley.</p>
            <p>1.4	Para efectos de esta Política de Privacidad, toda referencia a “nos”, “nosotros” o “nuestra”, se refiere a <strong>Operativa</strong>; y cuando se refiera a “el Usuario”, “los Usuarios” o alguna variación de éstos, se entenderá a todos aquellos individuos distintos a <strong>Operativa</strong> registrados como Postulantes Usuarios o Empresas Clientes en la Plataforma.</p>
            <p>1.5	Esta Política de Privacidad no aplica a sitios web, servicios, productos o aplicaciones de terceros, incluso si pueden ser accedidos a través de nuestros Servicios o Plataforma.</p>
            <h3>II.	INFORMACIÓN PERSONAL QUE RECOLECTAMOS DE LOS USUARIOS</h3>
            <p>2.1	<strong>Operativa</strong> recibe y almacena la información que los Usuarios proporcionan al registrarse como Usuarios de la Plataforma, a fin de poder desarrollar nuestra relación y brindar nuestros Servicios.</p>
            <p>2.2	Toda información proporcionada por los Usuarios deberá ser verdadera, completa y exacta. Cada Usuario es responsable por la veracidad, exactitud, vigencia y autenticidad de la información suministrada.</p>
            <p>2.3	La información de los Usuarios es necesaria para la ejecución de la relación contractual, motivo por el cual debe ser obligatoriamente proporcionada. De lo contrario implicará la imposibilidad de contratar el uso de los Servicios o el acceso a la Plataforma ofrecidos por <strong>Operativa</strong>.</p>
            <p>2.4	En caso se realice un registro en nombre de un tercero, el Usuario declara que ha obtenido su consentimiento antes de facilitarnos su información personal.</p>
            <h3>III.	ALCANCE Y PLAZO DE CONSERVACIÓN DE LOS DATOS PERSONALES</h3>
            <p>3.1	La información personal brindada por los Usuarios de la Plataforma será almacenada en el <strong>Banco de Datos</strong> de titularidad de <strong>Operativa</strong>, inscrita en el Registro Nacional de Protección de Datos Personales, bajo el código RNPDP-PJP-15491.</p>
            <p>3.2	Los datos personales serán conservados durante el tiempo en que los Usuarios mantengan una relación con <strong>Operativa</strong> y, con posterioridad al término de ésta, se mantendrán por un total de diez (10) años, para poder cumplir con las obligaciones legales pertinentes. Transcurrido dicho tiempo, serán eliminados.</p>
            <p>3.3	Sin perjuicio de lo anterior, <strong>Operativa</strong> podrá conservar determinada información de los Usuarios que solicitan la baja, a fin de que sirva de prueba ante una eventual reclamación contra <strong>Operativa</strong> por responsabilidades derivadas del tratamiento de dicha información o de la relación que mantenían. Dicha conservación será de diez (10) años, correspondiente al plazo de prescripción legal por responsabilidad contractual.</p>
            <h3>IV.	RESPONSABLE DE SUS DATOS PERSONALES Y TITULAR DEL BANCO DE DATOS PERSONALES</h3>
            <p><strong>SOLUCIONES EN GESTIÓN DE PERSONAL S.A.C.</strong>, con domicilio en el Jirón Alfonso Ugarte Nº 235 - Oficina 103, distrito de Barranco, provincia y región Lima, será el responsable del tratamiento, uso, almacenamiento y divulgación de sus datos personales, conforme a lo establecido en la presente Política de Privacidad.</p>
            <h3>V.	DATOS PERSONALES QUE RECABARA Y ALMACENARA LA PLATAFORMA</h3>
            <p>Los datos personales que la Plataforma podrá recabar serán:</p>
            <ul>
                <li>Nombres y apellidos completos.</li>
                <li>Sexo.</li>
                <li>Fecha de nacimiento.</li>
                <li>Ubicación.</li>
                <li>Número telefónico.</li>
                <li>Correo electrónico.</li>
                <li>Antecedentes laborales.</li>
                <li>Antecedentes académicos.</li>
            </ul>
            <h3>VI.	FORMAS DE OBTENER LOS DATOS PERSONALES</h3>
            <p>6.1	La Plataforma podrá obtener los datos personales directamente de los Usuarios o de forma automática permitida por Ley, a través herramientas de recopilación de datos, de cookies u otras funcionalidades que permiten ubicarlo en el entorno digital.</p>
            <p>6.2	Los Usuarios proporcionaran su información personal indicada en la sección V, a través de los siguientes mecanismos:</p>
            <ul>
                <li>a.	Comunicación directa (vía llamada, telefónica, correo electrónico y/o cualquier otro medio de comunicación) con <strong>Operativa</strong>.</li>
                <li>b.	Solicitudes de registro, suscripción, información y/o uso de Servicios o Plataforma de <strong>Operativa</strong>.</li>
                <li>c.	Encuestas voluntarias online o físicas.</li>
                <li>d.	Publicaciones en sitios web de terceros, o en blogs, foros, noticias, entre otros, ofrecidos por <strong>Operativa</strong>.</li>
                <li>e.	Promociones.</li>
                <li>f.	Al participar en algún sorteo o concurso desarrollado por <b>Operativa</b>.</li>
            </ul>
            <p>6.3	Como se indicó en el numeral 2.3, todos los datos recolectados son necesarios para la ejecución de la relación con los Usuarios y, por tanto, serán tratadas únicamente para tal finalidad.</p>
            <h3>VII.	USO DE SUS DATOS PERSONALES</h3>
            <p>7.1	Los datos personales con los que contará la Plataforma, se administrarán y se tratarán para las finalidades detalladas en la presente sección. Usted manifiesta aceptar y dar consentimiento libre, previo, expreso, inequívoco e informado para el tratamiento de sus datos personales a favor de <strong>SOLUCIONES EN GESTIÓN DE PERSONAL S.A.C.</strong>, para los siguientes fines:</p>
            <ul>
                <li>a.	Proporcionar y administrar los Servicios, lo que incluye mostrar contenido personalizado y facilitar la comunicación con los Usuarios.</li>
                <li>b.	Procesar las solicitudes de acceso a los Servicios o Plataforma.</li>
                <li>c.	Gestionar las preferencias de los Usuarios, a fin de mejorar la calidad del Plataforma y de los Servicios ofrecidos; así como, para desarrollar nuevos Servicios o funcionalidades en la Plataforma.</li>
                <li>d.	Facilitar el funcionamiento técnico de los Servicios, lo que incluye la solución de problemas, la seguridad de los Servicios o Plataforma y la prevención del fraude.</li>
                <li>e.	Enviar notificaciones relacionadas con las modificaciones que se realicen a la presente Política de Privacidad y de los T&C.</li>
                <li>f.	Supervisar, monitorear y moderar el comportamiento y la actividad de los Usuarios en la Plataforma y los Servicios; con el fin de detectar actos fraudulentos e irregulares.</li>
            </ul>
            <p>7.2	Los Usuarios es libre de aceptar o negar su consentimiento para el tratamiento de sus datos personales; sin embargo, en caso decida negarse a dicho tratamiento, <strong>Operativa</strong> no podrá cumplir con las finalidades descritas anteriormente.</p>
            <p>7.3	<strong>Operativa</strong> no recopila ni almacena datos confidenciales del titular de la tarjeta de crédito que se registran en la Plataforma (como todos los números de la tarjeta de crédito o los datos de autenticación de la tarjeta).</p>
            <p>7.4	<strong>Operativa</strong> utiliza distintas pasarelas de pago para poder realizar todos los cobros, como Visa, MasterCard, American Express, PayPal, las cuales procesan toda esta información y al aceptar la Política de Privacidad está de acuerdo en compartir su información con ellas.</p>
            <p>7.5	La información de los Usuarios podrá ser utilizada y divulgada en los siguientes escenarios:</p>
            <ul>
                <li>a.	Procesos y defensa ante tribunales o en cualquier otro proceso legal.</li>
                <li>b.	Transferencia de información en caso de venta, fusión, consolidación o adquisición de la empresa.</li>
                <li>c.	<strong>Operativa</strong> podrá compartir información con sus socios estratégicos (Plataforma FiVe, VERIFICATIVA S.A.C., CONSULTIVA S.A.C.).</li>
                <li>d.	La información de los Usuarios podrá ser compartida con empresas que presten servicios a <strong>Operativa</strong>, a sus socios comerciales, proveedores de análisis, inversionistas y proveedores de redes sociales, publicidad y encuestas. Señalados en la Plataforma con la finalidad de segmentar publicidad que pudiera brindar <strong>Operativa</strong> (sea propia o de terceros) y propósitos estadísticos (análisis de perfiles e interacción en la Plataforma).</li>
            </ul>
            <p>7.6	<strong>Operativa</strong>, se compromete a proteger sus datos personales. Protegemos su información contra malos usos, no autorizados o divulgación con tecnología y procesos utilizados.</p>
            <p>7.7	En caso de que no se desee autorizar el uso de sus datos personales para alguna de las finalidades señaladas en la presente Política de Privacidad de forma particular, los Usuarios podrán comunicarlo vía e-mail al correo electrónico: <strong>contacto@operativa.pe</strong>.</p>
            <p>7.8	Asimismo, los Usuarios podrán revocar su consentimiento al tratamiento de sus datos personales en cualquier momento enviándonos un e-mail al correo electrónico: <strong>contacto@operativa.pe</strong>.</p>
            <h3>VIII.	VINCULOS EXTERNOS</h3>
            <p>La Plataforma pueden contener enlaces (links) a otros sitios web que tienen sus propias políticas de privacidad y confidencialidad. Por ello, <strong>Operativa</strong> recomienda que, si usted visita dichos sitios, revise cuidadosamente sus prácticas y políticas de confidencialidad, toda vez que la presente <i>Política de Privacidad</i> no cubre las prácticas o políticas de terceros, incluyendo aquellos que pueden revelar y/o compartir información con <strong>Operativa</strong>.</p>
            <h3>IX.	PORTABILIDAD DE DATOS</h3>
            <p><strong>Operativa</strong> pone en conocimiento de los Usuarios que en caso tenga sus datos registrados en nuestro <strong>Banco de Datos</strong>, puede ejercer su derecho a la <i>Portabilidad de Datos</i> hacia otro responsable del tratamiento de su información. Con el fin de Portar sus Datos, el Usuario deberá solicitarlo a través del correo electrónico: <strong>contacto@operativa.pe</strong>, brindando los datos y el contacto del nuevo responsable del tratamiento de su información.</p>
            <h3>X.	FLUJO TRANSFRONTERIZO DE DATOS PERSONALES</h3>
            <p>Se informa que <strong>Operativa</strong> contrata los servidores u ordenadores de Amazon Web Services (AWS), ubicados en EE.UU. Este (Ohio), para prestar los Servicios. Los Usuarios autorizan y brindan su consentimiento previo, informado, libre, expreso e inequívoco a <strong>Operativa</strong> para transferir, almacenar y procesar sus datos personales a nivel internacional (“<b><i>flujo transfronterizo</i></b>”), de acuerdo con las disposiciones establecidas en la Ley.</p>
            <h3>XI.	MEDIDAS DE SEGURIDAD Y CONFIDENCIALIDAD DE LA INFORMACION</h3>
            <p>11.1	La información personal será tratada con el grado de protección legalmente exigible para garantizar su seguridad y evitar su alteración, pérdida, tratamiento o acceso no autorizado, por lo que de igual manera y en caso de que un tercero necesite conocer de dicha información <strong>Operativa</strong> resguarda su información personal de acuerdo con estándares y procedimientos de seguridad y confidencialidad impuestos en el Perú de conformidad con la LPDP.</p>
            <p>11.2	<strong>Operativa</strong> no transmite, divulga o proporciona la información personal recopilada a terceros diferentes del titular de dicha información personal, salvo en los casos en los que existe una relación de encargo para el tratamiento de datos personales, cuando los Usuarios hayan consentido expresamente que su información personal sea transferida a terceros debidamente identificados en la cláusula de consentimiento o en caso ello sea requerido por autoridades públicas o se trate del estricto cumplimiento de un mandato legal.</p>
            <p>11.3	En caso <strong>Operativa</strong> sea adquirida por alguna persona, cambie de titulares, sea fusionada con otras empresas, se escinda o reorganice o sufra cualquier otra modificación societaria, la información personal brindada continuará siendo conservada según lo establecido en esta Política de Privacidad y utilizada para los mismos fines que los aquí establecidos.</p>
            <h3>XII.	MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, OPOSICIÓN Y CANCELACIÓN</h3>
            <p>12.1	Los Usuarios tienen derecho de acceder a sus datos personales que se encuentren en la Base de Datos de <strong>Operativa</strong> y a los detalles del tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos o cancelarlos cuando considere que resulten ser excesivos o innecesarios para las finalidades que justificaron su obtención u oponerse al tratamiento de los mismos para los fines específicos que no sean necesarios para el uso de la Plataforma, sus secciones o aplicaciones.</p>
            <p>12.2	En caso de que los Usuarios, como titulares de sus datos personales deseen ejercer cualquiera de los derechos antes mencionados, <strong>Operativa</strong> le solicita que envíe la solicitud de ejercicio de derechos que corresponda, a través del envío de un correo electrónico a la siguiente dirección: <strong>contacto@operativa.pe</strong>. Dicha solicitud deberá contener como mínimo la siguiente información: i) nombres y apellidos completos, ii) domicilio o correo electrónico, a efecto de dar respuesta a su solicitud en los plazos establecidos en la ley, iii) copia de su DNI, o cualquier otro documento que acredite su identidad, iv) descripción clara y precisa de los datos personales sobre los que se busca ejercer alguno de los derechos antes mencionados de acceso, rectificación, oposición, cancelación o revocación, así como la descripción clara y precisa de su solicitud, v) fecha y firma del solicitante y v) cualquier otro elemento o documento que facilite la localización de dichos datos personales.</p>
            <p>12.3	<strong>Operativa</strong> responderá cualquier solicitud completa en un plazo máximo de diez (10) días calendario o el máximo permitido por la Ley y su Reglamento, pudiendo ser ampliado por un plazo igual por razones debidamente justificadas. La respuesta de <strong>Operativa</strong> indicará si la solicitud de acceso, rectificación, cancelación u oposición es procedente y, en caso que así lo sea, <strong>Operativa</strong> hará efectiva la determinación de manera automática. <strong>Operativa</strong> proporcionará copias electrónicas de su información personal en caso de que los Usuarios ejerzan su derecho de acceso.</p>
            <h3>XIII.	MODIFICACIONES AL AVISO DE PRIVACIDAD</h3>
            <p>13.1	<strong>Operativa</strong> se reserva el derecho a modificar la Política de Privacidad, lo cual será comunicado vía correo electrónico en un plazo de (10) días calendario previos a su entrada en vigor.</p>
            <p>13.2	Cualquier cambio sustancial en el tratamiento de la información personal será comunicado oportunamente a los Usuarios. Además, todo cambio realizado estará siempre sujeto a la LPDP y el RLPDP.</p>
            <p>13.3	Si los Usuarios no se encuentran de acuerdo con la revisión y modificación de la presente política, deberán dejar de usar inmediatamente nuestros Servicios.</p>
        </div>
    )
}
