import React from 'react'

export default function PoliciesOfCookies(props) {


    return (
        <div className="p-4 text-justify">
            <h2>POLÍTICA DE COOKIES - OPERATIVA</h2>
            <p>La presente política de cookies, en adelante la “<b>Cookies</b>”, tiene por finalidad informar la manera en que <b>SOLUCIONES EN GESTION DE PERSONAL S.A.C.</b>, en adelante, “<b><b>Operativa</b></b>”, con R.U.C. N° 20549306072, con domicilio en el Jirón Alfonso Ugarte Nº 235 - Oficina 103, distrito de Barranco, provincia y región Lima; conjuntamente con las empresas que conforman el <b>Grupo Verificativa</b>, en adelante, el “<b><b>Grupo Verificativa</b></b>”, tratan la información y datos personales de sus usuarios que recopilan a través de Cookies y otras tecnologías.</p>
            <p>Los Cookies describen toda la tipología de información que se recaban de los Usuarios (Postulantes Usuarios o Empresas Clientes) mediante las formas anteriormente detalladas, y todos los tratamientos que se realizan con dicha información. Los Usuarios declaran haber leído y aceptado de manera previa y expresa la Política de Cookies sujetándose a sus disposiciones.</p>
            <h3>¿A QUIÉNES LES ALCANZA DE LA POLÍTICA DE COOKIES?</h3>
            <p>Para efectos de esta Política de Cookies toda referencia a “nos”, “nosotros” o “nuestra”, se refiere al <b>Grupo Verificativa</b>, y cuando se refiera a “el Usuario” o “los Usuarios”, se entenderá a todos aquellos distintos al <b>Grupo Verificativa</b> que naveguen dentro de los sitios web del <b>Grupo Verificativa</b>.</p>
            <p>Estas Políticas de Cookies no aplican a sitios web, servicios, productos o aplicaciones de terceros, incluso si pueden ser accedidos a través de nuestros Servicios.</p>
            <h3>¿QUÉ SON LAS COOKIES?</h3>
            <p>1.	Las Cookies son archivos que el sitio web o aplicación instala en el navegador o dispositivo (Smartphone, tableta, TV conectada) al acceder o durante el recorrido en determinadas páginas web o aplicaciones, y sirven para almacenar información sobre tu visita.</p>
            <p>2.	Las Cookies permiten almacenar ciertos datos sobre el dispositivo del Usuario o sobre la navegación del mismo, es decir, recopila información estadística, facilita ciertas funcionalidades técnicas, ver qué páginas han sido visitadas, el tiempo de conexión, la ciudad a la que está asignada la dirección IP desde la que se accede, el número de nuevos Usuarios, la frecuencia y reincidencia de las visitas, el tiempo de visita, el navegador o el operador o tipo de terminal desde el que se realiza la visita, analiza el funcionamiento de una página, detecta problemas técnicos, entre otros; y dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al Usuario. La mayoría de las Cookies se asocian a un Usuario, ordenador y dispositivo no identificable en el sentido de que no proporcionan referencias que permitan conocer datos personales. Un navegador sólo permite que un sitio web acceda a las Cookies que éste establece, no a las que establecen otros sitios web.</p>
            <p>3.	Las Cookies no pueden dañar su dispositivo y además son muy útiles, puesto que nos ayudan a identificar y resolver errores. Las Cookies pueden borrarse del disco duro si el Usuario así lo desea. La mayoría de los navegadores aceptan las Cookies de forma automática, pero le permiten al Usuario cambiar la configuración de su navegador para que rechace la instalación de Cookies, sin que ello perjudique su acceso y navegación.</p>
            <p>4.	Algunas Cookies son estrictamente necesarias para que el sitio web funcione correctamente, así como para garantizar la continuidad del Servicio o para realizar mejoras en la Plataforma, y otras sirven para mejorar el rendimiento y su experiencia como Usuarios.</p>
            <p>5.	El <b>Grupo Verificativa</b> utiliza Cookies mediante la cual identifica el tipo de navegador, sistema operativo, página de referencia, avance a través del sitio, y dirección IP, entre otros, exclusivamente con el objeto de facilitar la manera en que los visitantes utilizan los sitios del <b>Grupo Verificativa</b>, que nos permiten mejorar los servicios que ofrecemos.</p>
            <p>6.	En el supuesto que en el sitio web se coloquen enlaces o hipervínculos que te redireccionen a otros lugares de Internet, que son de propiedad de terceros que utilicen Cookies; <b>Operativa</b> o el <b>Grupo Verificativa</b> no se hacen responsables del uso de Cookies por parte de dichos terceros.</p>
            <h3>¿QUÉ TIPO DE COOKIES USAMOS?</h3>
            <p>El <b>Grupo Verificativa</b> utiliza los siguientes tipos de cookies:</p>
            <ul>
                <b>i.	Por finalidad:</b>
                <ul>a.	<b>Cookies Técnicas. -</b> Permiten navegar al usuario en la página web, plataforma o aplicación, así como la utilización de las diferentes opciones que existen en ellas.</ul>
                <ul>b.	<b>Cookies de Geolocalización. –</b> Identifican la ubicación del Usuario.</ul>
                <ul>c.	<b>Cookies de Personalización. -</b> Permiten acceder a un servicio dependiendo de las características del Usuario, como idioma, tipo de navegador, entre otros.</ul>
                <ul>d.	<b>Cookies Analíticas. -</b> Miden la actividad del sitio web para elaboración de perfiles de navegación para incorporar mejoras.</ul>
                <ul>e.	<b>Cookies Publicitarias. -</b> Ayudan a gestionar la oferta publicitaria, adecuando el contenido al Servicio solicitado por el Usuario.</ul>
            </ul>
            <ul>
                <b>ii.	Por duracion:</b>
                <ul>a.	<b>Cookies de Sesión. -</b> Recaban y almacenan datos mientras el Usuario accede a una página web. No se almacenan en el ordenador y se eliminan cuando el Usuario cierra el navegador o su cesión caduca.</ul>
                <ul>b.	<b>Cookies Permanentes. -</b> Los datos se mantienen almacenados en el ordenador del Usuario como un historial. Son tratados cuando el Usuario abandona la página web y pueden ser eliminadas por éste.</ul>
            </ul>
            <ul>
                <b>ii.	Por gestión:</b>
                <ul><b>Cookies del Grupo Verificativa</b>. - Se envían desde un terminal del <b>Grupo Verificativa</b> al ordenador del Usuario.</ul>
                <ul><b>Cookies de Terceros. -</b> Se envían desde un equipo o dominio de un tercero al ordenador del Usuario o la información recolectada sea gestionada por un tercero. Cabe precisar que, las Cookies de terceros podrían encontrarse dentro de los sitios web y aplicaciones del <b>Grupo Verificativa</b>; sin embargo, al no ser administrados por nosotros, es que no garantizamos que sus políticas cumplan con la normativa legal actual.</ul>
            </ul>
            <h3>¿PARA QUÉ USAMOS LA INFORMACIÓN QUE ES RECOLECTADA A TRAVÉS “COOKIES”?</h3>
            <p>El <b>Grupo Verificativa</b> puede utilizar la información recolectada a través de Cookies para los siguientes fines:</p>
            <ul>
                <b>A.	Operatividad y Seguridad del Sistema:</b>
                <ul>i.	Uso de “Cookies” u otras tecnologías para acceder a los sitios web, servicios, aplicaciones, herramientas, entre otros, del <b>Grupo Verificativa</b>.</ul>
                <ul>ii.	Los “Cookies” u otras tecnologías identificarán y analizarán los comportamientos de los Usuarios en los sitios web y aplicaciones del <b>Grupo Verificativa</b>, con el fin de identificar actos irregulares, prevenir actividad fraudulenta y mejorar la seguridad de nuestros servicios.</ul>
            </ul>
            <ul>
                <b>B.	Funcionalidad del Sistema:</b>
                <ul>A través de las Cookies, los sitios web y las aplicaciones podrán utilizar la información de su visita para realizar evaluaciones, cálculos estadísticos sobre datos anónimos y conteo de visitas a los sitios web, con el fin de garantizar la continuidad del Servicio y realizar mejoras.</ul>
            </ul>
            <ul>
                <b>C.	Envío de Contenido Personalizado:</b>
                <ul>
                    Los sitios web y las aplicaciones también utilizarán la información obtenida a través de las Cookies u otras tecnologías para analizar los hábitos de navegación del Usuario y las búsquedas realizadas por éste, a fin de mejorar sus iniciativas comerciales y promocionales, mostrando publicidad que pueda ser de su interés, y personalizando los Servicios.
                </ul>
            </ul>
            <h3>CONTROL DE LOS “COOKIES” Y OTRAS TECNOLOGÍAS</h3>
            <p>Las Cookies pueden borrarse del disco duro si el Usuario así lo desea. La mayoría de los navegadores aceptan las Cookies de forma automática, pero le permiten al Usuario cambiar la configuración de su navegador para que rechace la instalación de Cookies, sin que ello perjudique su acceso y navegación por los sitios web y las aplicaciones.</p>
            <p>Es importante recalcar que, en algunos casos, el impedir la generación de algunos “Cookies” específicos; podría generar que varias funciones dejen de estar disponibles o que partes del sitio web no se cargue.</p>
            <p>En el supuesto de que en los sitios web y las aplicaciones se dispusieran enlaces o hipervínculos hacia otros lugares de Internet propiedad de terceros que utilicen Cookies, el <b>Grupo Verificativa</b> no se hace responsable ni controla el uso de Cookies por parte de dichos terceros.</p>
            <h3>CONSENTIMIENTO</h3>
            <p>El <b>Grupo Verificativa</b> puede tratar la información recopilada a través de “Cookies”, siempre que el Usuario brinde su consentimiento al ingresar a cualquiera de nuestros sitios web y aplicaciones, salvo en los casos en que las “Cookies” sean necesarias para la operatividad y navegación de las mismas.</p>
            <p>En caso de que el Usuario preste su consentimiento, el <b>Grupo Verificativa</b> podrá utilizar “Cookies” que permitan obtener información acerca de sus preferencias, con el fin de personalizar nuestros sitios web y aplicaciones, de acuerdo con sus intereses.</p>
            <h3>REVISIÓN Y MODIFICACIÓN DE LA POLÍTICA DE COOKIES</h3>
            <p>El <b>Grupo Verificativa</b> se reserva expresamente el derecho a modificar, actualizar o completar en cualquier momento la presente Política de Cookies. Cualquier modificación, actualización o ampliación producida en la presente Política de Cookies será inmediatamente publicada en los sitios web y las aplicaciones.</p>
        </div>
    )
}
