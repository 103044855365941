export const dashboardType = {
    SET_JOBS_INFO: 'DASHBOARD/SET_JOBS_INFO',
    SET_JOBS_INFO_ERROR: 'DASHBOARD/SET_JOBS_INFO_ERROR',
    SET_PUBLICATIONS_INFO: 'DASHBOARD/SET_PUBLICATIONS_INFO',
    SET_PUBLICATIONS_INFO_ERROR: 'DASHBOARD/SET_PUBLICATIONS_INFO_ERROR',
    SET_UPDATE_PUBLICATION_ERROR: 'DASHBOARD/SET_UPDATE_PUBLICATION_ERROR',
    SET_SAVE_PUBLICATION_ERROR: 'DASHBOARD/SET_SAVE_PUBLICATION_ERROR',
    SET_PUBLICATION_SELECTED: 'DASHBOARD/SET_PUBLICATION_SELECTED',
    SET_ARCHIVE_PUBLICATION_ERROR: 'DASHBOARD/SET_ARCHIVE_PUBLICATION_ERROR',
    SET_DELETE_PUBLICATION_ERROR: 'DASHBOARD/SET_DELETE_PUBLICATION_ERROR',
    SET_POSTULANTS_BY_PUBLICATION_ID: 'DASHBOARD/SET_POSTULANTS_BY_PUBLICATION_ID',
    SET_POSTULANTS_BY_PUBLICATION_ID_ERROR: 'DASHBOARD/SET_POSTULANTS_BY_PUBLICATION_ID_ERROR',
    SET_PROFILE_OF_APPLICANT: 'DASHBOARD/SET_PROFILE_OF_APPLICANT',
    SET_PROFILE_OF_APPLICANT_ERROR: 'DASHBOARD/SET_PROFILE_OF_APPLICANT_ERROR',
    SET_HISTORY_OF_PUBLICATIONS: 'DASHBOARD/SET_HISTORY_OF_PUBLICATIONS',
    SET_REPORT_BY_POSTULANT_ID: 'DASHBOARD/SET_REPORT_BY_POSTULANT_ID',
    SET_PUBLICATION_ID: 'DASHBOARD/SET_PUBLICATION_ID',
    SET_STATUS: 'DASHBOARD/SET_STATUS',
    SET_ERROR_FETCH: 'DASHBOARD/SET_ERROR_FETCH',
    SET_REQUEST_STATE: 'DASHBOARD/SET_REQUEST_STATE',
    SET_POSTULANTS_SELECTED: 'DASHBOARD/SET_POSTULANTS_SELECTED',
    SET_MESSAGE_STATUS: 'DASHBOARD/SET_MESSAGE_STATUS',
    SET_APPLICANT_NAME : 'DASHBOARD/SET_APPLICANT_NAME',
    SET_QUERYPARAMS : 'DASHBOARD/SET_QUERYPARAMS',
    SET_DASHBOARD_VALUE: 'DASHBOARD/SET_DASHBOARD_VALUE'
};
